const _ = require('lodash');

const sortExamsByName = (exams, countryCode) => {
  const examsCL = [
    'examen de altura física',
    'examen de gran altura geográfica',
    'test de drogas y alcohol',
    'examen psicosensotécnico',
    'exámenes psicolaborales',
    'examen exposición al sílice',
    'examen de exposición a polvos neumoconiógenos',
    'examen exposición a ruidos',
    'examen aversión al riesgo',
    'examen espacios confinados',
    'examen de manipulador de alimentos',
    'examen buzo (hiperbaria)',
    'examen básico sin riesgo laboral',
  ];

  const examsPE = [
    'examen anexo 16 + 16a, ascenso a grandes altitudes (2500 msnm)',
    'examen médico ocupacional básico (bajo riesgo)',
    'examen altura estructural (1.8 m) + examen médico ocupacional',
    'examen altura estructural  (1.8 m) + anexo 16 + 16a',
    'examen espacios confinados + examen médico ocupacional',
    'examen espacios confinados + anexo 16 + 16a',
    'examen exposición al plomo + anexo 16 + 16a',
    'examen exposición al ruido + examen médico ocupacional',
    'examen exposición al ruido + anexo 16 + 16a',
    'examen conductores de vehículos menores + examen médico ocupacional',
    'examen conductores de vehículos menores + anexo 16 + 16a',
    'examen conductores profesionales y especializados + examen médico ocupacional',
    'examen conductores profesionales y especializados + anexo 16 + 16a',
    'examen polvo mineral respirable + anexo 16 + 16a',
    'examen de drogas y alcohol',
  ];


  const givenOrder = countryCode === 'CL' ? examsCL : examsPE;

  const sortedData = _.sortBy(exams, (item) => {
    const name = item.title.toLowerCase();
    const orderIndex = givenOrder.indexOf(name);
    return orderIndex !== -1 ? orderIndex : givenOrder.length;
  });

  return sortedData;
};

module.exports = {
  sortExamsByName,
};