import { useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import "./PublicLayout.less"
import { Row, Col, Layout, Menu, Button, Popover, Affix, Avatar } from "antd"
import LogoCmtNaranjo2023 from "../images/LogoCmtNaranjo2023.svg"
import Info from './Info'
import { isLoggedIn, getUserName } from '../services/auth'
import { handlers } from "../helpers/handlers"
import { useTranslation } from "react-i18next"
import { HasAccess } from "@permify/react-role"
import MobilePublicLayout from "./MobilePublicLayout"
import FooterPublicLayout from "./FooterPublicLayout"

const { Header, Content, Footer } = Layout;
const sortByName = require(`../helpers/sortByExamName`);

const PublicLayout = ({ children, props, page }) => {
  const [userName, setUserName] = useState();
  const [showExamsList, setShowExamsList] = useState({
    open: false,
    type: null,
  });
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const { t } = useTranslation();
  const { isMobile } = handlers.useDeviceDetect();


  useEffect(() => {
    isUserLoggedIn();
  }, []);

  const isUserLoggedIn = () => {
    if (isLoggedIn()) {
      setUserName(getUserName());
    }
  };

  const handlePopoverAction = (open, type) => {
    if (open && (['exams', 'terrain'].includes(type))) {
      setShowExamsList(showList => ({
        ...showList,
        open: !(showExamsList.type === type),
        type: showExamsList.type === type ? null : type,
      }));
    }
  };

  const popoverVisibility = (newOpen) => {
    if (!newOpen) {
      setShowExamsList(showList => ({
        ...showList,
        open: false,
        type: null,
      }));
    }
  };

  
  const handleExamsOrder = (data, device) => {
    const filterByCountry = data.wpgraphql.exams.nodes.filter(exam => exam.fields.country === countryCode);
    const sortExams = sortByName.sortExamsByName(filterByCountry, countryCode);

    if (device === 'device-desktop') {
      return (
        <>
          {
            sortExams.map((exam, index) => {
              return (
                <div key={index}>
                  <Link to={`/examenes/${exam.slug}/`}>
                    <p className='mb-15'>{exam.title}</p>
                  </Link>
                </div>
              )
            })
          }
        </>
      );
    }

    if (device === 'device-mobile') {
      return (
        <>
          {
            sortExams.map((exam) => {
              return (
                <Menu.Item key={exam.slug} className='services-list'>
                  <Link to={`/examenes/${exam.slug}/`}>
                    <p className='mb-0'>{exam.title}</p>
                  </Link>
                </Menu.Item>
              )
            })
          }
        </>
      );
    }
  };


  const servicesContentPopover = (data) => {
    return (
      <Row className='header-sub-menu pt-20'>
        <Col lg={24}>
          <h4 className='mb-15'>Servicios de Salud Laboral</h4>
        </Col>
        <Col lg={24}>
          <Row>
            <Col lg={(!showExamsList.open) ? 24 : 12}>
              <Row
                gutter={5} align="middle" className="show-exams-wrapper mb-15"
                onClick={() => handlePopoverAction(true, 'exams')}
              >
                <Col>
                  <h5 className={`mb-0 ${showExamsList.type === 'exams' ? 'orange' : ''}`}>
                    {t('navbar.ourServicesTitle')}
                  </h5>
                </Col>
                <Col>
                  <span className={`icon-system-chevron-right ${showExamsList.type === 'exams' ? 'rotate' : ''}`} />
                </Col>
              </Row>
              {
                countryCode === 'CL' &&
                <>
                  <Row
                    gutter={5} align="middle" className="show-terrain-wrapper mb-15"
                    onClick={() => handlePopoverAction(true, 'terrain')}
                  >
                    <Col>
                      <h5 className={`mb-0 ${showExamsList.type === 'terrain' ? 'orange' : ''}`}>
                        Operativos de salud en terreno
                      </h5>
                    </Col>
                    <Col>
                      <span className={`icon-system-chevron-right ${showExamsList.type === 'terrain' ? 'rotate' : ''}`} />
                    </Col>
                  </Row>
                  <Row>
                    <Link to='/unidades-de-salud-en-empresas/'>
                      <h5 className='mb-20'>Unidades de Salud en Empresas</h5>
                    </Link>
                  </Row>
                </>
              }
            </Col>
            <Col lg={12}
              className={(showExamsList.type !== 'exams') ? 'hide' : ''}
            >
              <Row gutter={[0, 20]}>
                <Col lg={24} className='all-services-wrapper'>
                  <Link
                    to={countryCode === 'CL' ? `/examenes-preocupacionales-ocupacionales/` : `/examenes-medicos-ocupacionales/`}
                  >
                    {t('navbar.seeAllBatteries')}
                  </Link>
                </Col>
                <Col lg={24}>
                  {handleExamsOrder(data, 'device-desktop')}
                  {
                    data.wpgraphql.otherbatteries.nodes.filter(exam => exam.fields.country === countryCode).map((exam, index) => {
                      return (
                        <div key={index}>
                          <Link to={`/examenes/${exam.slug}/`}>
                            <p className='mb-15'>{exam.fields.title}</p>
                          </Link>
                        </div>
                      )
                    })
                  }
                </Col>
                <Col lg={24}>
                  <p className='mb-0'>
                    Para {t('navbar.otherExams')} llámanos al:&nbsp;
                    <a href={`tel:${data.wpgraphql.settings.nodes.filter(setting => setting.fields.country === countryCode).map(setting => setting.fields.centralPhone)}`}>
                      <strong>
                        {data.wpgraphql.settings.nodes.filter(setting => setting.fields.country === countryCode).map(setting => handlers.splitPhone(setting.fields.centralPhone))
                        }
                      </strong>
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg={12}
              className={`terrain-submenu ${showExamsList.type !== 'terrain' ? 'hide' : ''}`}
            >
              <Link to="/operativos-de-salud-en-terreno/">
                <p className='mb-15'>Tipos de operativos de salud en terreno</p>
              </Link>
              <Link to='/operativos-de-alcohol-y-drogas-en-terreno/'>
                <p className='mb-15'>Alcohol y Drogas</p>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };


  const dataQuery = useStaticQuery(
    graphql`
      query ServiceQuery {
        wpgraphql {
          exams(first: 50, where: {orderby: {field: TITLE, order: ASC}}) {
            nodes {
              fields {
                country
              }
              title
              slug
            }
          }
          otherbatteries {
            nodes {
              slug
              fields {
                title
                country
              }
            }
          }
          settings {
            nodes {
              fields {
                country
                centralPhone
                centralEmail
                noticeEmail
                facebook
                youtube
                twitterX
                linkedin
                instagram
                collectionPayments
                frequentQuestions
                opinion
              }
            }
          }
        }
      }
    `
  );


  return (
    <Layout className={`layout-${page}`}>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
        />
      </Helmet>

      <Info
        data={dataQuery}
        device='device-desktop'
        isMobile={isMobile}
        splitPhone={handlers.splitPhone}
        countryCode={countryCode}
      />

      <Affix offsetTop={0}>
        <Header className="header-public-layout">
          <Row justify='center' align="middle" className='main-wrapper'>
            <Col xl={21} lg={22} xs={22}>
              <Row align="middle">
                <Col xl={4} lg={3} xs={10} className="logo-container">
                  <Row align="middle">
                    <Col>
                      <Link to="/">
                        <img src={LogoCmtNaranjo2023} alt="CMT logo naranjo" />
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col xl={20} lg={21} xs={14}>
                  <Row align="middle">
                    <Col lg={24} xs={24}>
                      <Menu mode="horizontal" className="menu-desktop">
                        <Menu.Item key="1">
                          <Popover
                            overlayClassName={`services-public-popover ${!showExamsList.open && 'min-width'}`}
                            placement="bottomLeft"
                            content={servicesContentPopover(dataQuery)}
                            trigger="click"
                            onOpenChange={popoverVisibility}
                          >
                            Servicios
                          </Popover>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Link
                            to={`/${t('navbar.branchOffices').toLocaleLowerCase()}/`}
                            activeClassName="active"
                          >
                            {t('navbar.branchOffices')}
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                          {
                            isLoggedIn()
                              ? <HasAccess
                                permissions="can_reserve"
                              >
                                <Link
                                  to="/app/reservar/"
                                  activeClassName="active"
                                >
                                  <strong>{t("navbar.scheduleTime")}</strong>
                                </Link>
                              </HasAccess>
                              : <Link
                                to="/app/reservar/"
                                activeClassName="active"
                              >
                                <strong>{t("navbar.scheduleTime")}</strong>
                              </Link>
                          }
                        </Menu.Item>
                        <Menu.Item key="4">
                          {
                            isLoggedIn()
                              ? <HasAccess
                                permissions="can_download_results"
                              >
                                <Link
                                  to="/app/reservas?from=login"
                                  activeClassName="active"
                                >
                                  <strong>Descarga de resultados</strong>
                                </Link>
                              </HasAccess>
                              : <Link
                                to="/app/login?redirect=/app/reservas?from=login"
                                activeClassName="active"
                              >
                                <strong>Descarga de resultados</strong>
                              </Link>
                          }

                        </Menu.Item>
                        <Menu.Item key="5">
                          <Link
                            to="/app/checker/"
                            activeClassName="active"
                          >
                            <strong>Verificación de exámenes</strong>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="6" className='user-wrapper'>
                          <Link
                            to={
                              (isLoggedIn() && userName) ? '/app/' : '/app/login/'
                            }
                          >
                            {
                              (isLoggedIn() && userName) ?
                                <Row>
                                  <Col className="mr-10">
                                    <p className="mb-0">{userName}</p>
                                  </Col>
                                  <Col>
                                    <Avatar>{userName.charAt()}</Avatar>
                                  </Col>
                                </Row>
                                :
                                <Row justify="center" align="middle">
                                  <Col>
                                    <Button
                                      className="cmt-button primary"
                                      type="primary"
                                    >
                                      Acceso clientes
                                    </Button>
                                  </Col>
                                </Row>
                            }
                          </Link>
                        </Menu.Item>
                      </Menu>

                      <MobilePublicLayout
                        t={t}
                        data={dataQuery && dataQuery}
                        handlers={handlers}
                        userName={userName}
                        HasAccess={HasAccess}
                        isLoggedIn={isLoggedIn}
                        countryCode={countryCode}
                        handleExamsOrder={handleExamsOrder}
                      />

                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
      </Affix>

      <Content>
        <Row justify="center">
          <Col lg={24} xs={24}>
            {children}
          </Col>
        </Row>
      </Content>

      <FooterPublicLayout
        Link={Link}
        data={dataQuery && dataQuery}
        Footer={Footer}
        handlers={handlers}
        countryCode={countryCode}
      />
    </Layout>
  );
};

export default PublicLayout;